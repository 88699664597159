import request from '@/utils/request'

export function GetdragOrderList (data) {
  return request({
    url: 'api/DragOrder/GetdragOrder',
    method: 'post',
    data
  })
}

export function GetUserDragOrderList (data) {
  return request({
    url: 'api/DragOrder/GetUserDragOrder',
    method: 'post',
    data
  })
}

export function dragOrderAdd (data) {
  console.log('dragorder', data)
  return request({
    url: 'api/DragOrder/AddDragOrder',
    method: 'post',
    data
  })
}

export function dragOrderEdit (data) {
  return request({
    url: 'api/DragOrder/EditDragOrder',
    method: 'post',
    data
  })
}

export function dragOrderDelete (id) {
  return request({
    url: 'api/DragOrder/DeleteDragOrder',
    method: 'get',
    params: {
      id
    }
  })
}

export function getDragOrderById (id) {
  return request({
    url: 'api/DragOrder/GetDragOrderById',
    method: 'get',
    params: {
      id
    }
  })
}

export function codeGetDragOrderById (id) {
  return request({
    url: 'api/DragOrder/CodeGetDragOrderById',
    method: 'get',
    params: {
      id
    }
  })
}

export function DownLoadPDF (id,ImgBaseList) {
  return request({
    url: 'api/DragOrder/DownLoadDragOrder',
    method: 'post',
    data: {
      id,
      ImgBaseList
    }
  })
}

export function DownLoadPDFByData (dragData,ImgBaseList) {
  return request({
    url: 'api/DragOrder/DownLoadDragByData',
    method: 'post',
    data: {
      dragData,
      ImgBaseList
    }
  })
}

export function DownLoadExcel (id,ImgBaseList) {
  return request({
    url: 'api/DragOrder/DownLoadDragOrderExcel',
    method: 'post',
    data: {
      id,
      ImgBaseList
    }
  })
}

export function DownLoadExcelByData (dragData,ImgBaseList) {
  return request({
    url: 'api/DragOrder/DownLoadDragExcelByData',
    method: 'post',
    data: {
      dragData,
      ImgBaseList
    }
  })
}

export function DownLoadPPT (id,ImgBaseList) {
  return request({
    url: 'api/DragOrder/DownLoadDragOrderPPT',
    method: 'post',
    data: {
      id,
      ImgBaseList
    }
  })
}

export function DownLoadPPTByData (dragData,ImgBaseList) {
  return request({
    url: 'api/DragOrder/DownLoadDragPPTByData',
    method: 'post',
    data: {
      dragData,
      ImgBaseList
    }
  })
}

export function uploadeFloorFile (data) {
  return request({
    url: 'api/DragOrder/UploadeFloorImage',
    method: 'post',
    data,
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}


export function ImgUploadOSS (imageUrl) {
  return request({
    url: 'api/DragOrder/UploadOSS',
    method: 'post',
    data: {
      imageUrl
    }
  })
}